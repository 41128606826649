<template>
    <div v-loading="$wait.is('loading')" class="card card-f-h w-full self-start manager-general" element-loading-spinner="el-custom-spinner">
        <header class="border-b border-solid border-gray-300 pb-4 mb-5">
            <h1>{{ $t('manager_details.employees') }}</h1>
        </header>

        <EmployeesTable :tableData="employeesData" />
    </div>
</template>

<script>
export default {
    components: {
        EmployeesTable: () => import('./components/ManagerEmployeesTable.vue'),
    },

    data() {
        return {
            employeesData: [],
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'employees');
    },

    async created() {
        this.$wait.start('loading');
        const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/managers/${this.$route.params.uuid}/employees`);
        this.employeesData = data;
        this.$wait.end('loading');
    },
};
</script>
